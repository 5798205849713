import type { PaginateResult } from "mongoose"
import type { Asset } from "./asset.store"
import type { DomainType } from "./organization.store"
import type { FileData } from "./file.store"

export interface LookupType {
  _id: string
  organization?: string
  description: string
  parent?: string
  domain: DomainType
  lookupValueCount?: number
}

export interface LookupValue {
  _id: string
  organization?: string
  lookupType: string
  parent?: string
  description: string
  price?: number
  priceYear?: number
  files: Array<string>
}

export interface LookupValueUsage {
  _id: string
  organization?: string
  lookupType: string
  parent?: string
  description: string
  price?: number
  priceYear?: number
  filesCount: number
  assetCount: number
  isValid?: boolean
}

export type LookupAssetResponse = Pick<Asset, "_id" | "key" | "description" | "organization">

export interface LookupAssetWithOrganization extends Pick<Asset, "_id" | "key" | "description"> {
  organization: {
    _id: string
    description: string
  }
}

export const useLookupValueStore = defineStore("lookupValue", () => {
  const getLookupValuesByPage = async (queryParameters: Record<string, unknown> = {}): Promise<PaginateResult<LookupValue>> => {
    const query = getUrlSearchParams(queryParameters)

    const { data } = await useSamApi<PaginateResult<LookupValue>>(`/lookup-values?${query}`)
    return data
  }

  const getLookupValueUsageByPage = async (queryParameters: Record<string, unknown> = {}): Promise<PaginateResult<LookupValueUsage>> => {
    const query = getUrlSearchParams(queryParameters)

    const { data } = await useSamApi<PaginateResult<LookupValueUsage>>(`/lookup-values/usage?${query}`)
    return data
  }

  const getLookupValuesByType = async (lookupType: string, parentValue: string | undefined, pagination = false) => {
    const parent = parentValue ? { parent: parentValue } : { parent: { $eq: null } }
    const params = new URLSearchParams({ query: JSON.stringify({ ...parent, lookupType }), pagination: pagination.toString() })

    const { data } = await useSamApi<PaginateResult<LookupValue>>(`/lookup-values?${params}`)
    return data
  }

  const getLookupValueById = async (id: string) => {
    const { data } = await useSamApi<LookupValue>(`/lookup-values/${id}`)
    return data
  }

  const updateLookupValue = async (id: string, lookupValue: Partial<LookupValue>) => {
    const { data } = await useSamApi<LookupValue>(`/lookup-values/${id}`, { method: "PUT", body: JSON.stringify(lookupValue) })
    return data
  }

  const createLookupValue = async (lookupValue: Partial<LookupValue>) => {
    const { data } = await useSamApi<LookupValue>(`/lookup-values`, { method: "POST", body: JSON.stringify(lookupValue) })
    return data
  }

  const deleteLookupValue = async (id: string) => {
    const { data } = await useSamApi<LookupValue>(`/lookup-values/${id}`, { method: "DELETE" })
    return data
  }

  const getAssetsByLookupValue = async (id: string) => {
    const { data } = await useSamApi<Array<LookupAssetWithOrganization>>(`/lookup-values/${id}/assets`)
    return data
  }

  const getLookupTypesByPage = async (queryParameters: Record<string, unknown> = {}) => {
    const query = getUrlSearchParams(queryParameters)

    const { data } = await useSamApi<PaginateResult<LookupType>>(`/lookup-types?${query}`)
    return data
  }

  const getLookupTypeById = async (id: string) => {
    const { data } = await useSamApi<LookupType>(`/lookup-types/${id}`)
    return data
  }

  const updateLookupType = async (id: string, lookupType: Partial<LookupType>) => {
    const { data } = await useSamApi<LookupType>(`/lookup-types/${id}`, { method: "PUT", body: JSON.stringify(lookupType) })
    return data
  }

  const createLookupType = async (lookupType: Partial<LookupType>) => {
    const { data } = await useSamApi<LookupType>(`/lookup-types`, { method: "POST", body: JSON.stringify(lookupType) })
    return data
  }

  const deleteLookupType = async (id: string) => {
    const { data } = await useSamApi<LookupType>(`/lookup-types/${id}`, { method: "DELETE" })
    return data
  }

  const addFiles = async (id: string, files: Array<File>) => {
    const formData = new FormData()
    files.forEach((file) => formData.append("files", file))

    const { data } = await useSamApi<Array<FileData>>(
      `/lookup-values/${id}/files`,
      { method: "POST", body: formData },
      { forceNoContentType: true },
    )
    return data
  }

  const updateFiles = async (id: string, files: Array<string>) => {
    const { data } = await useSamApi<FileData>(`/lookup-values/${id}/files`, { method: "PUT", body: JSON.stringify({ files }) })
    return data
  }

  const deleteFile = async (id: string, fileId: string) => {
    const { data } = await useSamApi<FileData>(`/lookup-values/${id}/files/${fileId}`, { method: "DELETE" })
    return data
  }

  const replaceLookupValue = async (id: string, replacementId: string) => {
    const { data } = await useSamApi<LookupType>(`/lookup-values/${id}/replace/${replacementId}`, { method: "PUT" })
    return data
  }

  return {
    getLookupValuesByPage,
    getLookupValueUsageByPage,
    getLookupValuesByType,
    getLookupValueById,
    updateLookupValue,
    createLookupValue,
    deleteLookupValue,

    getAssetsByLookupValue,

    getLookupTypesByPage,
    getLookupTypeById,
    updateLookupType,
    createLookupType,
    deleteLookupType,
    replaceLookupValue,

    addFiles,
    updateFiles,
    deleteFile,
  }
})
